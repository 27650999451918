<template>
    <div>
      <Card>
        <template #header>Yer Gösterimleri</template>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <Input placeholder="Ara.." v-model="search" class="mr-2" />
          </div>
          <div class="d-flex align-items-center">
            <b-form-group>
              <b-button variant="primary" @click="addFileModal = true"
                >Yeni Yer Gösterim Randevusu</b-button
              ></b-form-group
            >
          </div>
        </div>
      </Card>
  
      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Sistem Kullanıcı Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item.action="{ item }">
          <div class="d-flex">
            <button class="btn p-0">
              <i class="flaticon-eye text-dark text-md"></i>
            </button>
            <button class="btn p-0">
              <i class="flaticon2-trash text-dark text-md"></i>
            </button>
            <button class="btn p-0">
              <i class="flaticon-download text-dark text-md"></i>
            </button>
          </div>
        </template>
      </List>
      <b-modal v-model="addFileModal" id="note-modal">
        <template #modal-header>
          <h4><i class="flaticon2-plus mr-4"></i> Yeni Görüşme</h4>
        </template>
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="aciklama">Randevu Oluşturma Tarihi</label>
              <input type="date" class="form-control" id="aciklama" />
            </div>
          </div>
          <div class=" col-12">
            <div class="form-group">
              <label for="aciklama">Yer Gösterimi Yapılan Müşteri  </label>
              <select name="" id="" class="form-control">
                <option value="" selected>Seçiniz</option>
                <option value="">a</option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="type">Randevuyu Organize Eden </label>
              <select name="" id="type" class="form-control">
                <option value="" selected>Seçiniz</option>
                <option value="">Diğer</option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="type">Yer Gösterimi Yapan</label>
              <select name="" id="type" class="form-control">
                <option value="" selected>Seçiniz</option>
                <option value="">Diğer</option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="aciklama">Talep Edilen Yer Gösterim Tarihi</label>
              <input type="date" class="form-control" id="aciklama" />
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="aciklama">Yer Gösterim Tarihi</label>
              <input type="date" class="form-control" id="aciklama" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="type">Potansiyel Alıcı mı?</label>
              <select name="" id="type" class="form-control">
                <option value="" selected>Seçiniz</option>
                <option value="">Diğer</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="type"
                >Yer Gösterimi Yapıldı mı?</label
              >
              <select name="" id="type" class="form-control">
                <option value="" selected>Seçiniz</option>
                <option value="">Diğer</option>
              </select>
            </div>
          </div>
          
        </div>
  
        <template #modal-footer>
          <div class="d-flex justify-content-end w-100">
            <b-button variant="light" @click="addFileModal = false">
              Vazgeç
            </b-button>
            <b-button variant="success" @click="addNewNote" class="ml-4">
              Kaydet
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  
  export default {
    name: "systemUsers",
    data() {
      return {
        addFileModal: false,
        search: "",
        filters: "",
        showInsertUpdate: false,
        showDetail: false,
        showImportModal: false,
        resetForm: false,
        confirmCreate: false,
        loading: false,
        header: [
          { text: "Organize Eden", value: "aciklama" },
          { text: "Müşteri", value: "tip" },
          { text: "Talep Edilen Tarih", value: "tarih" },
          { text: "Görüşme Yapıldı mı?", value: "tarih" },
          { text: "Potansiyel Alıcı mı?", value: "tarih" },
          { text: "İşlemler", value: "action" },
        ],
        items: [],
        pagination: {},
        meta: {},
        listSelectedItemIds: [],
        selectedItem: "",
        deleteOperationUrl: "",
        deleteIds: "",
      };
    },
    
    created() {
      this.getList();
    },
    methods: {
      getList() {
        this.loading = true;
  
        this.totalItems = 3;
  
        this.pagination = {
          current_page: 1,
          total_pages: 1,
          total_items: 3,
        };
        this.loading = false;
  
        // this.items = [];
        // this.totalItems = 0;
  
        // let queryLimit = this.perPage ? this.perPage : 10;
        // let query = `user/list?Page=${
        //   this.page ? this.page : 1
        // }&limit=${queryLimit}`;
        // if (this.search) {
        //   query += `&search=${this.search}`;
        // }
  
        // this.$dbFunctions
        //   .get(query)
        //   .then((res) => {
        //     if (res.success == true && res.count > 0) {
        //       this.items = res.data;
        //       this.totalItems = res.count;
  
        //       this.pagination = {
        //         current_page: res.page,
        //         total_pages: res.pageCount,
        //         total_items: res.count,
        //       };
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   })
        //   .finally(() => {
        //     this.loading = false;
        //   });
      },
      updatePage(value) {
        this.meta = value;
  
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
    },
    watch: {
      meta: {
        handler() {
          this.getList();
        },
        deep: true,
      },
      search: function (params) {
        this.getList();
      },
    },
  };
  </script>
  